/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 100;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 900;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Black.woff") format("woff");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Google Sans"),
    url("./assets/fonts/ProductSans-Bold.woff") format("woff");
}
body,
html {
  font-family: "Google Sans", sans-serif !important;
  background-color: #fff;
}
.face-camera-wrap {
  background-color: #0b101b !important;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  width: 100%;
  height: 100%;
  transition: width 2s ease, height 2s ease, border-radius 2s ease;
}

.face-camera {
  transform: scaleX(-1);
}
.minimize-camera {
  width: 290px;
  height: 290px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0px;
}

.minimize-camera video {
  transition: border-radius 2s ease;
  border-radius: 50%;
  transform: scaleX(-1);
}

.small-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0px;
}

.small-circle video {
  transition: border-radius 2s ease;
  border-radius: 50%;
  transform: scaleX(-1);
}

.small-circle-document {
  width: 120px;
  height: 100px;
  border-radius: 30%;
  margin: 0 auto;
  padding: 0px;
}

.small-circle-document video {
  transition: border-radius 2s ease;
  border-radius: 30%;
}

.dotted-border {
  position: relative;
}

.face-canvas {
  position: absolute;
  left: 49%;
  top: 51.5%;
  transform: translate(-50%, -50%);
}

.document-canvas {
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  padding: 15px;
}

.blur-camera {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.greenOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 3px solid green;
  background-color: #0080005e;
}

.passkeyList {
  list-style: inside !important;
}

.flipCard {
  transition: all 0.5s;
}
.flipAnimation {
  transition: all 0.5s;
  transform: rotateY(360deg);
}

.range-slider .range-slider__thumb, .range-slider .range-slider__range{
  background: #0c4888;
}

#experiment {
  perspective: 2000px;
  height: 100%; 
  width: 100%;
}

.cube {
  position: relative;
  height: 100%; 
  width: 100%; 
  transform-style: preserve-3d;
}
.face {
  position: absolute;
  height: 100%; 
  width: 100%;
  transition: transform 1s linear, opacity 1s linear;
}
.cube .front {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(90deg) translateX(50%) rotateY(90deg);
  transform-origin: center center;
  opacity: 0;
}

.cube .side {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(0deg) translateX(50%) rotateY(90deg); 
}

.cube .front.active {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(0deg) translateX(50%) rotateY(90deg);
  opacity: 1;
}

.cube .side.active {
  transform:  rotateY(-90deg) translateX(-50%) rotateY(-90deg) translateX(50%) rotateY(90deg);
  opacity: 0;
}

.sigCanvas {
  background-color: #cccccc8f;
  border: 2px solid #8c8a8a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.innerCardStore {
  box-shadow: 0px 1.5px 10px 0px #00000033;
}

.userIcon {
  filter: brightness(0) saturate(100%) invert(10%) sepia(4%) saturate(194%) hue-rotate(314deg) brightness(103%) contrast(89%);
}

.cardShadow {
  box-shadow: 0px 1.5px 10px 0px #00000033;
}

.smallCardOs {
  border: 1px solid #7F7F7F;
  box-shadow: 0px 1.5px 10px 0px #00000033;
}

.footerShadow {
  box-shadow: 0px 1.5px 10px 0px #00000033;
}

@media only screen and (min-width: 1950px) {
  .main-layout {
    height: 100vh;
  }
  .inner-layout {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 767px) {
  input {
    font-size: 16px !important;
  }
}
